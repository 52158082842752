#sign-in-wrapper {
    width: 100vw;
    height: 100vh;

    top: 0;
    left: 0;

    display: flex;
    position: fixed;

    align-items: center;
    justify-content: center;
}

#sign-in {
    width: 30rem;

    padding: 2rem;

    background-color: var(--foreground);

    text-align: center;

    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}

#sign-in img {
    width: 100%;

    margin-bottom: 2rem;
}

#sign-in button {
    width: 100%;
    height: 3rem;

    color: var(--foreground);
    font-weight: 700;
    font-size: 1.25rem;

    background-color: var(--psg-red);

    border: 0;

    transition: .1s;
}

#sign-in button:hover {
    cursor: pointer;

    transform: scale(1.05);
}
