.card {
    min-width: 0;
    padding: 2rem 2rem 0 2rem;

    background-color: var(--foreground);

    box-shadow: 2px 2px 2px rgba(0, 0, 0, .16);

    overflow-x: scroll;
}

.card::-webkit-scrollbar {
    background: transparent;
}

.card::-webkit-scrollbar-thumb {
    background: var(--psg-red);
}

.card::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
}

.card-description {
    margin: 0.75rem 0;
}

.link-details {
    width: 100%;
    text-align: left;
}

.link-actions {
    width: 100%;

    margin: 0.75rem 0;
}
