.card-group {
    margin: 2rem 0;

    display: grid;

    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
}

@media only screen and (max-width: 600px) {
    .card-group {
        grid-template-columns: 1fr;
    }
}
