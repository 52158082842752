nav {
    width: 100vw;
    height: 4.5rem;

    top: 0;
    left: 0;

    position: fixed;
    display: flex;

    align-items: center;
    justify-content: center;

    grid-template-columns: 3fr 1fr;

    background-color: var(--foreground);

    box-shadow: 1px 1px 1px rgba(0, 0, 0, .1);
}

.nav-content {
    width: 1000px;

    display: grid;

    grid-template-columns: 3fr 1fr;
}

.logo {
    width: 15rem;
    padding: 10px;
}

.nav-items {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 600px) {
    .nav-content {
        display: inline-block;
    }

    .logo {
        display: none;
    }
}
