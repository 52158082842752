.sign-out {
    width: 12rem;
    height: 2rem;

    color: var(--black);

    font-weight: 700;

    background-color: var(--foreground);

    border: 1px solid var(--black);
}

.sign-out:hover {
    color: var(--foreground);

    background-color: var(--psg-red);

    cursor: pointer;
}
