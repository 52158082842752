@import url('https://fonts.googleapis.com/css?family=Roboto:400:700&display=swap');

* { margin: 0; padding: 0; box-sizing: border-box; }

:root {
    --psg-red: #DB2A38;
    --foreground: #FFFFFF;
    --background: #E5E5E5;
    --black: #000000;
    --error: #A02D1B;
    font-size: 16px;
}

body {
    margin-top: 6rem;

    display: flex;
    justify-content: center;

    background-color: var(--background);

    font-family: 'Roboto', sans-serif;
}

main {
    max-width: 1000px;
    width: 1000px;
}

.loading {
    width: 100%;

    text-align: center;
}
