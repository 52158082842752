.create-link-form {
    width: 100%;

    margin: 25px 0 25px;

    padding: 25px;

    background-color: var(--foreground);

    box-shadow: 2px 2px 2px rgba(0, 0, 0, .16);
}

.create-link-form form {
    margin-top: 2rem;

    display: grid;

    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 25px;
    grid-row-gap: 15px;

    border: 0;
}

.create-link-form input {
    width: 100%;

    outline: none;

    border: 0;
    border-bottom: 1px solid var(--black);
}

.create-link-form input:focus {
    border-bottom: 2px solid var(--psg-red);
}

.input-error {
    color: var(--error);

    font-size: 75%;
}

.create-link-form button {
    color: #FFFFFF;

    font-weight: 700;
    font-size: 100%;

    background-color: var(--black);
    border: 0;

    transition: .2s;
}

.create-link-form button:hover {
    background-color: var(--psg-red);

    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .create-link-form form {
        grid-template-columns: 1fr;
    }

    .create-link-form button {
        height: 2rem;
    }
}

